import React, { useEffect, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import { func } from 'prop-types';
import QRCode from 'react-qr-code';
import { getTicketData, putAccessToken } from '../utils/api';
import AuthedContext from '../contexts/AuthedContext';
import PrintButton from '../components/PrintButton';

function Ticket({ setInitiate }) {
  const { authedUser } = useContext(AuthedContext);
  const [bookingData, setBookingData] = useState(null);
  const { state: { id, dataInput } } = useLocation();

  useEffect(() => {
    if (id) {
      getTicketData(id).then(({ statusCode, errorMessage, data }) => {
        if (data) {
          setBookingData([data]);
        } else if (statusCode === 401) {
          Swal.fire(
            'Failed',
            'Session anda telah habis, silahkan login kembali',
            'error',
          );
          putAccessToken('');
          setInitiate();
        } else {
          Swal.fire('Error', errorMessage, 'error');
        }
      });
    } else if (dataInput) {
      setBookingData(dataInput);
    }
  }, [id]);

  let backUrl = '';
  if (bookingData) {
    if (dataInput) {
      backUrl = `/bookingticket?shipid=${bookingData[0].ship_id}&departhour=${bookingData[0].depart_hour}&shiproute=${bookingData[0].ship_route_id}`;
    } else if (id) {
      backUrl = '/bookingdata';
    }

    setTimeout(() => {
      window.print();
    }, 1000);
  }

  return (
    <>
      <div className="d-flex d-print-none justify-content-between">
        {
          bookingData && <Link to={backUrl}>Kembali</Link>
        }
        <PrintButton />
      </div>
      <div className="ticket-print-area mx-auto text-center">
        {
          bookingData
            && bookingData.map((item) => (
              <>
                <div className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-3">
                      <img
                        src="images/belibis-logo.png"
                        alt="belibis-logo"
                        style={{
                          height: 'auto',
                          width: '100%',
                          maxWidth: '50px',
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <h6>PT. BELIBIS GROUP</h6>
                    </div>
                    <div className="col-3">
                      <QRCode
                        value={item.id}
                        size={356}
                        style={{ height: 'auto', width: '100%', maxWidth: '50px' }}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </div>

                  <h6>{`${item.seat_class}${item.seat_number} | KAPAL`}</h6>
                  <h6>{`${item.ship_name}, ${item.ship_id}`}</h6>

                  <table className="table table-borderless text-start">
                    <tr>
                      <td>Nomor Tiket</td>
                      <td>:</td>
                      <td>{item.id}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>{item.customer_name}</td>
                    </tr>
                    <tr>
                      <td>Rute</td>
                      <td>:</td>
                      <td>{`${item.embarkation} - ${item.destination}`}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{moment(item.date).format('D-MM-YYYY')}</td>
                    </tr>
                    <tr>
                      <td>Keberangkatan</td>
                      <td>:</td>
                      <td>{item.depart_hour}</td>
                    </tr>
                    <tr>
                      <td>Nomor Kursi</td>
                      <td>:</td>
                      <td>{`${item.seat_class + item.seat_number}`}</td>
                    </tr>
                    <tr>
                      <td>Harga Tiket</td>
                      <td>:</td>
                      <td>{`Rp. ${parseInt(item.price, 10).toLocaleString('id-ID')}`}</td>
                    </tr>
                    <tr>
                      <td>Operator</td>
                      <td>:</td>
                      <td>{authedUser.name}</td>
                    </tr>
                    <tr>
                      <td>Kategori</td>
                      <td>:</td>
                      <td>{item.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>:</td>
                      <td>{item.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                    </tr>
                  </table>
                  <small>TIKET YANG SUDAH DIBELI TIDAK DAPAT DIKEMBALIKAN</small>
                </div>

                <div className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-3">
                      <img
                        src="images/belibis-logo.png"
                        alt="belibis-logo"
                        style={{
                          height: 'auto',
                          width: '100%',
                          maxWidth: '50px',
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <h6>PT. BELIBIS GROUP</h6>
                    </div>
                    <div className="col-3">
                      <QRCode
                        value={item.id}
                        size={256}
                        style={{ height: 'auto', width: '100%', maxWidth: '50px' }}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </div>

                  <h6>{`${item.seat_class}${item.seat_number} | PENUMPANG`}</h6>
                  <h6>{`${item.ship_name}, ${item.ship_id}`}</h6>

                  <table className="table table-borderless text-start">
                    <tr>
                      <td>Nomor Tiket</td>
                      <td>:</td>
                      <td>{item.id}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>{item.customer_name}</td>
                    </tr>
                    <tr>
                      <td>Rute</td>
                      <td>:</td>
                      <td>{`${item.embarkation} - ${item.destination}`}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{moment(item.date).format('D-MM-YYYY')}</td>
                    </tr>
                    <tr>
                      <td>Keberangkatan</td>
                      <td>:</td>
                      <td>{item.depart_hour}</td>
                    </tr>
                    <tr>
                      <td>Nomor Kursi</td>
                      <td>:</td>
                      <td>{`${item.seat_class + item.seat_number}`}</td>
                    </tr>
                    <tr>
                      <td>Harga Tiket</td>
                      <td>:</td>
                      <td>{`Rp. ${parseInt(item.price, 10).toLocaleString('id-ID')}`}</td>
                    </tr>
                    <tr>
                      <td>Operator</td>
                      <td>:</td>
                      <td>{authedUser.name}</td>
                    </tr>
                    <tr>
                      <td>Kategori</td>
                      <td>:</td>
                      <td>{item.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>:</td>
                      <td>{item.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                    </tr>
                  </table>
                  <small>TIKET YANG SUDAH DIBELI TIDAK DAPAT DIKEMBALIKAN</small>
                </div>

                <div className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-3">
                      <img
                        src="images/belibis-logo.png"
                        alt="belibis-logo"
                        style={{
                          height: 'auto',
                          width: '100%',
                          maxWidth: '50px',
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <h6>PT. BELIBIS GROUP</h6>
                    </div>
                    <div className="col-3">
                      <QRCode
                        value={item.id}
                        size={256}
                        style={{ height: 'auto', width: '100%', maxWidth: '50px' }}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </div>

                  <h6>{`${item.seat_class}${item.seat_number} | OPERATOR`}</h6>
                  <h6>{`${item.ship_name}, ${item.ship_id}`}</h6>

                  <table className="table table-borderless text-start">
                    <tr>
                      <td>Nomor Tiket</td>
                      <td>:</td>
                      <td>{item.id}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>{item.customer_name}</td>
                    </tr>
                    <tr>
                      <td>Rute</td>
                      <td>:</td>
                      <td>{`${item.embarkation} - ${item.destination}`}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{moment(item.date).format('D-MM-YYYY')}</td>
                    </tr>
                    <tr>
                      <td>Keberangkatan</td>
                      <td>:</td>
                      <td>{item.depart_hour}</td>
                    </tr>
                    <tr>
                      <td>Nomor Kursi</td>
                      <td>:</td>
                      <td>{`${item.seat_class + item.seat_number}`}</td>
                    </tr>
                    <tr>
                      <td>Harga Tiket</td>
                      <td>:</td>
                      <td>{`Rp. ${parseInt(item.price, 10).toLocaleString('id-ID')}`}</td>
                    </tr>
                    <tr>
                      <td>Operator</td>
                      <td>:</td>
                      <td>{authedUser.name}</td>
                    </tr>
                    <tr>
                      <td>Kategori</td>
                      <td>:</td>
                      <td>{item.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>:</td>
                      <td>{item.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                    </tr>
                  </table>
                  <small>TIKET YANG SUDAH DIBELI TIDAK DAPAT DIKEMBALIKAN</small>
                </div>
              </>
            ))
        }
      </div>
    </>
  );
}

Ticket.propTypes = {
  setInitiate: func.isRequired,
};

export default Ticket;
