/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import PrintButton from '../components/PrintButton';

function Manifest() {
  const { state: { bookingData, dateParam } } = useLocation();
  let index = 0;

  const dataCount = {
    totalIndex: 0,
    adultCategoryCount: 0,
    childCategoryCount: 0,
    terbayar: 0,
    terscan: 0,
    terlewat: 0,
    berangkat: 0,
  };

  function countCategory(category) {
    if (category === 'adult') {
      dataCount.adultCategoryCount += 1;
    } else {
      dataCount.childCategoryCount += 1;
    }
  }

  function countStatus({ status }) {
    switch (status) {
      case 'terbayar':
        dataCount.terbayar += 1;
        break;
      case 'terscan':
        dataCount.terscan += 1;
        break;
      case 'terlewat':
        dataCount.terlewat += 1;
        break;
      case 'berangkat':
        dataCount.berangkat += 1;
        break;
      default: break;
    }
  }

  useEffect(() => {
    if (bookingData) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, []);

  // eslint-disable-next-line no-return-assign
  return (
    bookingData.length > 0
      ? (
        <div className="container manifest-print-area">
          <div className="d-print-none d-flex justify-content-between">
            <Link to={`/bookingdata?date=${dateParam}`}>Kembali ke tabel</Link>
            <PrintButton />
          </div>
          <div className="position-relative mb-2 ">
            <img src="images/logo64x.png" alt="belibis-logo" />
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <h6 className="text-uppercase pt-1">Perusahaan Pelayaran Nasional</h6>
              <h6>PT. BELIBIS GROUP</h6>
            </div>
          </div>

          <hr className="mx-0 m-2" />
          <h6 className="text-center">
            Manifest / Daftar Penumpang
            {' '}
            {bookingData[0].subsidy === '1' && '/ Subsidi'}
          </h6>
          <div className="row justify-content-between my-2">
            <div className="col-4">
              <table className="table table-borderless">
                <tr>
                  <td>Rute</td>
                  <td>:</td>
                  <td>{`${bookingData[0].embarkation} - ${bookingData[0].embark_id ? 'Transit' : bookingData[0].destination}`}</td>
                </tr>
                <tr>
                  <td>Jam Berangkat</td>
                  <td>:</td>
                  <td>{bookingData[0].depart_hour}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>{moment(bookingData[0].date).format('D-MM-YYYY')}</td>
                </tr>
              </table>
            </div>
            <div className="col-5">
              <table className="table table-borderless">
                <tr>
                  <td>Keberangkatan</td>
                  <td>:</td>
                  <td>{bookingData[0].embarkation}</td>
                </tr>
                <tr>
                  <td>Tujuan</td>
                  <td>:</td>
                  <td>{bookingData[0].embark_id ? 'Transit' : bookingData[0].destination}</td>
                </tr>
                <tr>
                  <td>Kapal</td>
                  <td>:</td>
                  <td>{`${bookingData[0].ship_name}, ${bookingData[0].ship_id}`}</td>
                </tr>
              </table>
            </div>
          </div>

          { bookingData.filter((item) => item.seat_class === 'VIP').length > 0 && (
            <>
              <h6>VIP</h6>
              <table className="table data table-bordered">
                <thead>
                  <tr>
                    <th scope="col" width="30">No.</th>
                    <th scope="col" width="120">Kode Booking</th>
                    <th scope="col" width="50">Kursi</th>
                    {
                      bookingData[0].embark_id
                      && <th scope="col" width="80">Rute</th>
                    }
                    <th scope="col">Nama Penumpang</th>
                    <th scope="col" width="70">Gender</th>
                    <th scope="col" width="70">Umur</th>
                    <th scope="col" width="70">Kategori</th>
                    {/* <th scope="col" width="70">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    bookingData.filter((item) => item.seat_class === 'VIP').map((row) => (
                      <tr>
                        <th className="text-center" scope="row">{++index}</th>
                        <td>{row.id}</td>
                        <td className="text-center">{row.seat_class + row.seat_number}</td>
                        {
                          row.embark_id
                          && <td className="text-center">{`${row.embark_id} - ${row.destine_id}`}</td>
                        }
                        <td>{row.customer_name}</td>
                        <td className="text-center">{row.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                        <td className="text-center">{row.customer_age}</td>
                        <td className="text-center">{row.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                        {/* <td className="text-center">{row.status}</td> */}
                        { countCategory(row.ticket_category) }
                        { countStatus(row) }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div className="row mt-1">
                <div className="col">
                  <p className="m-0">
                    {'Penumpang VIP: '}
                    <span className="fw-bold">{index}</span>
                  </p>
                </div>
              </div>
            </>
          )}

          { (() => {
            dataCount.totalIndex += index;
            index = 0;
          })() }

          { bookingData.filter((item) => item.seat_class !== 'VIP').length > 0 && (
            <>
              <h6 className="mt-2">Executive</h6>
              <table className="table data table-bordered">
                <thead>
                  <tr>
                    <th scope="col" width="30">No.</th>
                    <th scope="col" width="120">Kode Booking</th>
                    <th scope="col" width="50">Kursi</th>
                    {
                      bookingData[0].embark_id
                      && <th scope="col" width="80">Rute</th>
                    }
                    <th scope="col">Nama Penumpang</th>
                    <th scope="col" width="70">Gender</th>
                    <th scope="col" width="70">Umur</th>
                    <th scope="col" width="70">Kategori</th>
                    {/* <th scope="col" width="70">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    bookingData.filter((item) => item.seat_class !== 'VIP').map((row) => (
                      <tr>
                        <th scope="row">{++index}</th>
                        <td>{row.id}</td>
                        <td className="text-center">{row.seat_class + row.seat_number}</td>
                        {
                          row.embark_id
                          && <td className="text-center">{`${row.embark_id} - ${row.destine_id}`}</td>
                        }
                        <td>{row.customer_name}</td>
                        <td className="text-center">{row.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                        <td className="text-center">{row.customer_age}</td>
                        <td className="text-center">{row.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                        {/* <td className="text-center">{row.status}</td> */}
                        { countCategory(row.ticket_category) }
                        { countStatus(row) }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div className="row mt-1">
                <div className="col">
                  <p className="m-0">
                    {'Penumpang Executive: '}
                    <span className="fw-bold">{index}</span>
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="row mt-2">
            <div className="col">
              <p className="m-0">
                {'Jumlah Penumpang: '}
                <span className="fw-bold">{index + dataCount.totalIndex}</span>
              </p>
              <p className="m-0">
                {'Dewasa: '}
                <span className="fw-bold">{dataCount.adultCategoryCount}</span>
              </p>
              <p className="m-0">
                {'Anak: '}
                <span className="fw-bold">{dataCount.childCategoryCount}</span>
              </p>
            </div>
            <div className="col text-end">
              <p className="text-secondary fw-bold m-0">
                {'Terbayar: '}
                <span className="text-dark">{dataCount.terbayar}</span>
              </p>
              <p className="text-success fw-bold m-0">
                {'Terscan: '}
                <span className="text-dark">{dataCount.terscan}</span>
              </p>
              <p className="text-warning fw-bold m-0">
                {'Terlewat: '}
                <span className="text-dark">{dataCount.terlewat}</span>
              </p>
              <p className="text-primary fw-bold m-0">
                {'Berangkat: '}
                <span className="text-dark">{dataCount.berangkat}</span>
              </p>
            </div>
          </div>

          <div className="print-new-line mt-3">
            <p>Mengetahui:</p>
            <div className="row justify-content-between mt-5">
              <div className="col-3">
                <hr className="mb-1 mx-0" />
                <p className="text-center">Otoritas Pelabuhan</p>
              </div>
              <div className="col-3">
                <hr className="mb-1 mx-0" />
                <p className="text-start">Nahkoda:</p>
              </div>
              <div className="col-3">
                <hr className="mb-1 mx-0" />
                <p className="text-start">Ka. Operasional:</p>
              </div>
            </div>
          </div>
        </div>
      )
      : <h1>404 NOT FOUND</h1>
  );
}

export default Manifest;
